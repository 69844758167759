import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { Box } from "../Box";
import { Image } from "../Image";
import { EditButton } from "./components/EditButton";
import { RemoveButton } from "./components/RemoveButton";

const ImageWrapper = styled("span", {
  display: "inline-flex",
  position: "relative",
});

const StyledImagePreview = styled(Box, {
  "& *": {
    borderRadius: "0.5rem",
  },
  "&:hover": {
    opacity: "0.8",
  },
  variants: {
    clickable: {
      true: {
        cursor: "pointer",
      },
    },
    size: {
      large: {
        height: "$20",
        maxHeight: "$20",
        maxWidth: "$20",
        width: "$20",
      },
      medium: {
        height: "$16",
        maxHeight: "$16",
        maxWidth: "$16",
        width: "$16",
      },
      small: {
        height: "$14",
        maxHeight: "$14",
        maxWidth: "$14",
        width: "$14",
      },
      xlarge: {
        height: "$36",
        maxHeight: "$36",
        maxWidth: "$36",
        width: "$36",
      },
      xsmall: {
        height: "$10",
        maxHeight: "$10",
        maxWidth: "$10",
        width: "$10",
      },
      xxsmall: {
        height: "$8",
        maxHeight: "$8",
        maxWidth: "$8",
        width: "$8",
      },
    },
  },
});

type ImagePreviewProps = Pick<
  React.ComponentProps<typeof StyledImagePreview>,
  "size"
> & {
  mode?: "contain" | "cover";
  src: string;
  css?: CSS;
  onClick?: (event: React.MouseEvent) => unknown;
  onRemoveClick?: (event: React.MouseEvent) => unknown;
  onEditClick?: (event: React.MouseEvent) => unknown;
};

function ImagePreview(props: ImagePreviewProps) {
  const { css, mode, onEditClick, onRemoveClick, src, ...pictureProps } = props;

  return (
    <ImageWrapper>
      <StyledImagePreview
        {...pictureProps}
        background="image"
        clickable={Boolean(props.onClick)}
        onClick={(e) => props.onClick?.(e)}
        css={css}
      >
        <Image mode={mode} src={src} />
      </StyledImagePreview>
      {Boolean(props.onRemoveClick) && (
        <RemoveButton size={props.size} onClick={onRemoveClick} />
      )}
      {Boolean(props.onEditClick) && (
        <EditButton size={props.size} onClick={onEditClick} />
      )}
    </ImageWrapper>
  );
}

ImagePreview.defaultProps = {
  size: "medium",
};

export type { ImagePreviewProps };
export { ImagePreview };
