import { validationSchema } from "@clovis/server/src/app/domain/org/create-org/create-org.validation";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "~/config/react-hook-form";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import { PhoneField } from "~/design-system/PhoneField";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { Tones } from "~/design-system/tokens";
import { createComponentHook } from "~/types";
import { useCreateOrgMutation } from "./CreateOrgForm.graphql";

type CreateOrgFormProps = {
  onSuccess?: (orgId: string) => void;
};

const useCreateOrgForm = createComponentHook((props: CreateOrgFormProps) => {
  const { t } = useTranslation();
  const form = useForm(validationSchema);
  const [, createOrg] = useCreateOrgMutation();

  const handleSubmit = form.handleSubmit(async (input) => {
    const { data } = await createOrg(
      {
        input: {
          ...input,
        },
      },
      { additionalTypenames: ["users"] }
    );
    if (data?.createOrg?.__typename === "CreateOrgSuccess") {
      toast.success(
        <span data-intercom-target="org-created">
          {t(
            "CreateOrgDrawer.CreateOrgForm.CreateOrgSuccessToast",
            "Your organization has been created"
          )}
        </span>
      );
      form.reset();
      props.onSuccess?.(data.createOrg.org_id);
    } else {
      toast.error(
        t(
          "CreateOrgDrawer.CreateOrgForm.CreateOrgSuccessErrors",
          "Something went wrong while creating the organization"
        )
      );
    }
  });

  return {
    actions: {
      handleSubmit,
      registerInput: form.register,
    },
    state: {
      control: form.control,
      errors: form.formState.errors,
    },
    t,
  };
});

function CreateOrgForm(props: CreateOrgFormProps) {
  const { actions, state, t } = useCreateOrgForm(props);

  return (
    <Form
      onSubmit={actions.handleSubmit}
      data-intercom-target="create-org-form"
    >
      <Stack space="medium">
        <TextField
          {...actions.registerInput("name")}
          label={t(
            "CreateProjectDrawer.components.CreateOrgForm.nameLabel",
            "Name"
          )}
          error={state.errors.name?.message}
          required
          dataIntercomTarget="org-create-name"
        />
        <TextField
          {...actions.registerInput("description")}
          label={t(
            "CreateProjectDrawer.components.CreateOrgForm.descriptionLabel",
            "Description"
          )}
          error={state.errors.description?.message}
          multiline
          dataIntercomTarget="org-create-description"
        />
        <TextField
          {...actions.registerInput("legal_number")}
          label={t(
            "CreateProjectDrawer.components.CreateOrgForm.legalNumberLabel",
            "Legal Number"
          )}
          error={state.errors.legal_number?.message}
          dataIntercomTarget="org-create-legal_number"
        />
        <Controller
          control={state.control}
          name="phone"
          render={({ field: { name, onChange, value } }) => (
            <PhoneField
              label={t(
                "CreateProjectDrawer.components.CreateOrgForm.phoneLabel",
                "Phone"
              )}
              value={value}
              onChange={onChange}
              name={name}
              tone={state.errors.phone ? Tones.critical : undefined}
              error={state.errors.phone?.message}
              dataIntercomTarget="org-create-phone"
            />
          )}
        />
        <Button
          dataIntercomTarget="create-org"
          size="large"
          tone="brandAccent"
          width="full"
        >
          {t(
            "CreateProjectDrawer.components.CreateOrgForm.submitButton",
            "Create organization"
          )}
        </Button>
      </Stack>
    </Form>
  );
}

export type { CreateOrgFormProps };
export { CreateOrgForm };
