import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { env } from "~/config/env";
import type { CountryCode } from "~/config/i18next/helpers";
import {
  DEFAULT_COUNTRY_CODE,
  getAvailableLanguage,
  getCountryFromBrowser,
} from "~/config/i18next/helpers";
import type { UseFormReturn } from "~/config/react-hook-form";
import { Controller, useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import { PhoneField } from "~/design-system/PhoneField";
import { getCountryCodeFromPhone } from "~/design-system/PhoneField/components/PhoneFieldInput";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { Tones } from "~/design-system/tokens";
import { SelectLanguageField } from "~/screens/App/components/SelectLanguageField/SelectLanguageField";
import { createComponentHook } from "~/types";

const validationSchema = z.object({
  first_name: z.string().nonempty(),
  language: z.string().nonempty(),
  last_name: z.string().nonempty(),
  phone: z.string().optional(),
});

type Auth0ContinueLoginForm = UseFormReturn<z.infer<typeof validationSchema>>;

const AUTH0_CONTINUE_LOGIN_URL = `https://${env.VITE_AUTH0_DOMAIN}/continue`;

const getCountryCodeFromIP = async () => {
  /* limit rate of 1000 a day, 30 000 a month */
  try {
    const res = await fetch("https://ipapi.co/json");
    const jsonres = (await res.json()) as { country_code?: CountryCode };
    return jsonres.country_code ? jsonres.country_code : DEFAULT_COUNTRY_CODE;
  } catch (err) {
    captureException(err);
    return DEFAULT_COUNTRY_CODE;
  }
};

const useAuth0ContinueLoginForm = createComponentHook(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationSearchParams = new URLSearchParams(location.search);
  const { languageCode } = getCountryFromBrowser();
  const browserLanguage = getAvailableLanguage(languageCode);

  const first_name = locationSearchParams.get("first_name");
  const last_name = locationSearchParams.get("last_name");
  const phone = locationSearchParams.get("phone");

  const form = useForm(validationSchema, {
    defaultValues: {
      first_name: first_name ?? undefined,
      language: browserLanguage,
      last_name: last_name ?? undefined,
      phone: phone ? phone : undefined,
    },
  });

  const handleSubmit = form.handleSubmit(async (input) => {
    locationSearchParams.set("first_name", input.first_name);
    locationSearchParams.set("last_name", input.last_name);
    locationSearchParams.set("language", input.language);
    if (input.phone) {
      locationSearchParams.set("phone", input.phone);
      const countryCode = getCountryCodeFromPhone(input.phone);
      locationSearchParams.set(
        "country_code",
        countryCode ?? DEFAULT_COUNTRY_CODE
      );
    } else {
      /* if no phone is provided, get countryCode from IP */
      const countryCode = await getCountryCodeFromIP();
      locationSearchParams.set("country_code", countryCode);
    }
    locationSearchParams.set("redirect_uri", env.VITE_APP_BASE_URL);
    // Continue the login onto auth0 side
    window.location.assign(
      `${AUTH0_CONTINUE_LOGIN_URL}?${locationSearchParams.toString()}`
    );
  });

  return {
    actions: {
      control: form.control,
      handleSubmit,
      registerInput: form.register,
    },
    state: { errors: form.formState.errors },
    t,
  };
});

function Auth0ContinueLoginForm() {
  const { actions, state, t } = useAuth0ContinueLoginForm();

  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="gutter">
        <TextField
          {...actions.registerInput("first_name")}
          label={t("components.App.Auth0ContinueLog.firstName", "First Name")}
          tone={Tones.neutral}
          error={state.errors.first_name?.message}
          required
          dataIntercomTarget="auth0-continue-login-first_name"
        />
        <TextField
          {...actions.registerInput("last_name")}
          label={t("components.App.Auth0ContinueLog.lastName", "Last Name")}
          tone={Tones.neutral}
          error={state.errors.last_name?.message}
          required
          dataIntercomTarget="auth0-continue-login-last_name"
        />
        <SelectLanguageField
          {...actions.registerInput("language")}
          label={t("components.App.Auth0ContinueLog.language", "Language")}
          required
          error={state.errors.language?.message}
          dataIntercomTarget="auth0-continue-login-language"
        />
        <Controller
          control={actions.control}
          name="phone"
          render={({ field: { name, onChange, value } }) => (
            <PhoneField
              label={t("components.App.Auth0ContinueLog.phone", "Phone")}
              value={value}
              onChange={onChange}
              name={name}
              error={state.errors.phone?.message}
              required
              dataIntercomTarget="auth0-continue-login-phone"
            />
          )}
        />
        <Button
          size="large"
          tone="brandAccent"
          width="full"
          dataIntercomTarget="auth0-continue-login-screen-continue"
        >
          {t(
            "components.App.Auth0ContinueLog.continueButton",
            "Continue Login"
          )}
        </Button>
      </Stack>
    </Form>
  );
}

export { Auth0ContinueLoginForm };
