import { validationSchema } from "@clovis/server/src/app/domain/project_category/create-project_category/create-project_category.validation";
import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { createComponentHook } from "~/types";
import { SelectColorField } from "../../../../../../../../components/SelectColorField/SelectColorField";
import { useCreateProjectCategoryMutation } from "./CreateCategoryForm.graphql";

const useCreateCategoryForm = createComponentHook(
  (props: CreateCategoryFormProps) => {
    const { t } = useTranslation();
    const [addMode, setAddMode] = React.useState(false);
    const [, createProjectCategory] = useCreateProjectCategoryMutation();

    const form = useForm(validationSchema, {
      defaultValues: {
        orgId: props.orgId,
      },
    });

    const enableForm = () => {
      setAddMode(true);
    };

    const disableForm = () => {
      form.reset();
      setAddMode(false);
    };

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data } = await createProjectCategory(
        {
          input,
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.createProjectCategory?.__typename ===
        "CreateProjectCategorySuccess"
      ) {
        toast.success(
          t(
            "screens.OrgProjectCategoryDrawer.CreateCategoryForm.createProjectCategorySuccessToast",
            "Your category has been added"
          )
        );
        disableForm();
      } else {
        toast.error(
          t(
            "screens.OrgProjectCategoryDrawer.CreateCategoryForm.createProjectCategoryErrorsToast",
            "Something went wrong while creating the category."
          )
        );
      }
    });

    return {
      actions: {
        disableForm,
        enableForm,
        handleSubmit,
        registerInput: form.register,
      },
      state: {
        addMode,
        control: form.control,
        errors: form.formState.errors,
      },
      t,
    };
  }
);

type CreateCategoryFormProps = {
  orgId: string;
};

function CreateCategoryForm(props: CreateCategoryFormProps) {
  const { actions, state, t } = useCreateCategoryForm(props);

  if (state.addMode) {
    return (
      <Form onSubmit={actions.handleSubmit}>
        <Stack space="small">
          <TextField
            {...actions.registerInput("name")}
            label={t(
              "screens.OrgProjectCategoryDrawer.CreateCategoryForm.nameLabel",
              "Name"
            )}
            error={state.errors.name?.message}
            required
            dataIntercomTarget="org-project-categories-create-name"
          />
          <SelectColorField
            {...actions.registerInput("color")}
            label={t(
              "screens.OrgProjectCategoryDrawer.CreateCategoryForm.colorLabel",
              "Color"
            )}
            control={state.control}
            error={state.errors.color?.message}
            required
            dataIntercomTarget="org-project-categories-create-color"
          />
          <Columns space="small">
            <Column>
              <Button
                size="large"
                variant="soft"
                width="full"
                onClick={actions.disableForm}
                type="button"
                dataIntercomTarget="org-project-categories-create-cancel"
              >
                {t(
                  "screens.OrgProjectCategoryDrawer.CreateCategoryForm.cancelButton",
                  "Cancel"
                )}
              </Button>
            </Column>
            <Column>
              <Button
                size="large"
                tone="brandAccent"
                width="full"
                dataIntercomTarget="org-project-categories-create-submit"
              >
                {t(
                  "screens.OrgProjectCategoryDrawer.CreateCategoryForm.submitButton",
                  "Create category"
                )}
              </Button>
            </Column>
          </Columns>
        </Stack>
      </Form>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Button
        type="button"
        tone="brandAccent"
        leadingIcon={<PlusSmIcon />}
        onClick={actions.enableForm}
        dataIntercomTarget="org-project-categories-create-submit"
      >
        {t(
          "screens.OrgProjectCategoryDrawer.CreateCategoryForm.enableButton",
          "Add a category"
        )}
      </Button>
    </Box>
  );
}

export { CreateCategoryForm };
