import type * as Types from '../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FinalizeUploadedFileMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedFileInput;
}>;


export type FinalizeUploadedFileMutation = { finalizeUploadedFile?: { __typename: 'FinalizeUploadedFileErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedFileSuccess', file_id: string } | null | undefined };

export type FinalizeUploadedFileVersionMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedFileVersionInput;
}>;


export type FinalizeUploadedFileVersionMutation = { finalizeUploadedFileVersion?: { __typename: 'FinalizeUploadedFileVersionErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'FileDoesNotExists' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedFileVersionSuccess', file_version_id: string } | null | undefined };

export type FinalizeUploadedTaskAttachmentMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedTaskAttachmentInput;
}>;


export type FinalizeUploadedTaskAttachmentMutation = { finalizeUploadedTaskAttachment?: { __typename: 'FinalizeUploadedTaskAttachmentErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedTaskAttachmentSuccess', task_attachment_id: string } | null | undefined };

export type FinalizeUploadedTaskAttachmentAnnotationMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedTaskAttachmentAnnotationInput;
}>;


export type FinalizeUploadedTaskAttachmentAnnotationMutation = { finalizeUploadedTaskAttachmentAnnotation?: { __typename: 'FinalizeUploadedTaskAttachmentAnnotationErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' } | { __typename: 'TaskAttachmentDoesNotExist' }> } | { __typename: 'FinalizeUploadedTaskAttachmentAnnotationSuccess', task_attachment_id: string } | null | undefined };

export type FinalizeUploadedOrgAvatarMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedOrgAvatarInput;
}>;


export type FinalizeUploadedOrgAvatarMutation = { finalizeUploadedOrgAvatar?: { __typename: 'FinalizeUploadedOrgAvatarErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedOrgAvatarSuccess', org_id: string } | null | undefined };

export type FinalizeUploadedOrgBackgroundMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedOrgBackgroundInput;
}>;


export type FinalizeUploadedOrgBackgroundMutation = { finalizeUploadedOrgBackground?: { __typename: 'FinalizeUploadedOrgBackgroundErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedOrgBackgroundSuccess', org_id: string } | null | undefined };

export type FinalizeUploadedProjectAvatarMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedProjectAvatarInput;
}>;


export type FinalizeUploadedProjectAvatarMutation = { finalizeUploadedProjectAvatar?: { __typename: 'FinalizeUploadedProjectAvatarErrors', errors: Array<{ __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedProjectAvatarSuccess', project_id: string } | null | undefined };

export type FinalizeUploadedProjectGroupAvatarMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedProjectGroupAvatarInput;
}>;


export type FinalizeUploadedProjectGroupAvatarMutation = { FinalizeUploadedProjectGroupAvatar?: { __typename: 'FinalizeUploadedProjectGroupAvatarErrors', errors: Array<{ __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedProjectGroupAvatarSuccess', project_group_id: string } | null | undefined };

export type FinalizeUploadedProjectBackgroundMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedProjectBackgroundInput;
}>;


export type FinalizeUploadedProjectBackgroundMutation = { finalizeUploadedProjectBackground?: { __typename: 'FinalizeUploadedProjectBackgroundErrors', errors: Array<{ __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedProjectBackgroundSuccess', project_id: string } | null | undefined };

export type FinalizeUploadedProjectBannerMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedProjectBannerInput;
}>;


export type FinalizeUploadedProjectBannerMutation = { finalizeUploadedProjectBanner?: { __typename: 'FinalizeUploadedProjectBannerErrors', errors: Array<{ __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedProjectBannerSuccess', project_id: string } | null | undefined };

export type FinalizeUploadedUserAvatarMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedUserAvatarInput;
}>;


export type FinalizeUploadedUserAvatarMutation = { finalizeUploadedUserAvatar?: { __typename: 'FinalizeUploadedUserAvatarErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' }> } | { __typename: 'FinalizeUploadedUserAvatarSuccess', user_id: string } | null | undefined };

export type FinalizeUploadedChannelAssetMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedChannelAssetInput;
}>;


export type FinalizeUploadedChannelAssetMutation = { finalizeUploadedChannelAsset?: { __typename: 'FinalizeUploadedChannelAssetErrors', errors: Array<{ __typename: 'CopyFromTemporaryBucketFailed' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedChannelAssetSuccess', channel_id: string, public_url: string } | null | undefined };

export type FinalizeUploadedFileStructureMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedFileStructureInput;
}>;


export type FinalizeUploadedFileStructureMutation = { finalizeUploadedFileStructure?: { __typename: 'FinalizeUploadedFileStructureErrors', errors: Array<{ __typename: 'FileTooLarge' } | { __typename: 'InputValidationError' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedFileStructureSuccess', parent_id: string } | null | undefined };

export type FinalizeUploadedTaskSpreadsheetMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedTaskSpreadsheetInput;
}>;


export type FinalizeUploadedTaskSpreadsheetMutation = { finalizeUploadedTaskSpreadsheet?: { __typename: 'FinalizeUploadedTaskSpreadsheetErrors', errors: Array<{ __typename: 'FileTooLarge' } | { __typename: 'InputValidationError' } | { __typename: 'InvalidContentType' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedTaskSpreadsheetSuccess', project_id: string } | null | undefined };

export type FinalizeUploadedProjectSpreadsheetMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedProjectSpreadsheetInput;
}>;


export type FinalizeUploadedProjectSpreadsheetMutation = { finalizeUploadedProjectSpreadsheet?: { __typename: 'FinalizeUploadedProjectSpreadsheetErrors', errors: Array<{ __typename: 'FileTooLarge' } | { __typename: 'InputValidationError' } | { __typename: 'InvalidContentType' } | { __typename: 'KeyDoesNotExists' } | { __typename: 'MissingMetadata' } | { __typename: 'NotAuthorOfTheUpload' }> } | { __typename: 'FinalizeUploadedProjectSpreadsheetSuccess', org_id: string } | null | undefined };

export type FinalizeFileVersionAnnotationsForUploadMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type FinalizeFileVersionAnnotationsForUploadMutation = { __typename: 'mutation_root' };


export const FinalizeUploadedFileDocument = gql`
    mutation finalizeUploadedFile($input: FinalizeUploadedFileInput!) {
  finalizeUploadedFile(input: $input) {
    __typename
    ... on FinalizeUploadedFileSuccess {
      file_id
    }
    ... on FinalizeUploadedFileErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedFileMutation() {
  return Urql.useMutation<FinalizeUploadedFileMutation, FinalizeUploadedFileMutationVariables>(FinalizeUploadedFileDocument);
};
export const FinalizeUploadedFileVersionDocument = gql`
    mutation finalizeUploadedFileVersion($input: FinalizeUploadedFileVersionInput!) {
  finalizeUploadedFileVersion(input: $input) {
    __typename
    ... on FinalizeUploadedFileVersionSuccess {
      file_version_id
    }
    ... on FinalizeUploadedFileVersionErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedFileVersionMutation() {
  return Urql.useMutation<FinalizeUploadedFileVersionMutation, FinalizeUploadedFileVersionMutationVariables>(FinalizeUploadedFileVersionDocument);
};
export const FinalizeUploadedTaskAttachmentDocument = gql`
    mutation finalizeUploadedTaskAttachment($input: FinalizeUploadedTaskAttachmentInput!) {
  finalizeUploadedTaskAttachment(input: $input) {
    __typename
    ... on FinalizeUploadedTaskAttachmentSuccess {
      task_attachment_id
    }
    ... on FinalizeUploadedTaskAttachmentErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedTaskAttachmentMutation() {
  return Urql.useMutation<FinalizeUploadedTaskAttachmentMutation, FinalizeUploadedTaskAttachmentMutationVariables>(FinalizeUploadedTaskAttachmentDocument);
};
export const FinalizeUploadedTaskAttachmentAnnotationDocument = gql`
    mutation finalizeUploadedTaskAttachmentAnnotation($input: FinalizeUploadedTaskAttachmentAnnotationInput!) {
  finalizeUploadedTaskAttachmentAnnotation(input: $input) {
    __typename
    ... on FinalizeUploadedTaskAttachmentAnnotationSuccess {
      task_attachment_id
    }
    ... on FinalizeUploadedTaskAttachmentAnnotationErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedTaskAttachmentAnnotationMutation() {
  return Urql.useMutation<FinalizeUploadedTaskAttachmentAnnotationMutation, FinalizeUploadedTaskAttachmentAnnotationMutationVariables>(FinalizeUploadedTaskAttachmentAnnotationDocument);
};
export const FinalizeUploadedOrgAvatarDocument = gql`
    mutation finalizeUploadedOrgAvatar($input: FinalizeUploadedOrgAvatarInput!) {
  finalizeUploadedOrgAvatar(input: $input) {
    __typename
    ... on FinalizeUploadedOrgAvatarSuccess {
      org_id
    }
    ... on FinalizeUploadedOrgAvatarErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedOrgAvatarMutation() {
  return Urql.useMutation<FinalizeUploadedOrgAvatarMutation, FinalizeUploadedOrgAvatarMutationVariables>(FinalizeUploadedOrgAvatarDocument);
};
export const FinalizeUploadedOrgBackgroundDocument = gql`
    mutation finalizeUploadedOrgBackground($input: FinalizeUploadedOrgBackgroundInput!) {
  finalizeUploadedOrgBackground(input: $input) {
    __typename
    ... on FinalizeUploadedOrgBackgroundSuccess {
      org_id
    }
    ... on FinalizeUploadedOrgBackgroundErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedOrgBackgroundMutation() {
  return Urql.useMutation<FinalizeUploadedOrgBackgroundMutation, FinalizeUploadedOrgBackgroundMutationVariables>(FinalizeUploadedOrgBackgroundDocument);
};
export const FinalizeUploadedProjectAvatarDocument = gql`
    mutation finalizeUploadedProjectAvatar($input: FinalizeUploadedProjectAvatarInput!) {
  finalizeUploadedProjectAvatar(input: $input) {
    __typename
    ... on FinalizeUploadedProjectAvatarSuccess {
      project_id
    }
    ... on FinalizeUploadedProjectAvatarErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedProjectAvatarMutation() {
  return Urql.useMutation<FinalizeUploadedProjectAvatarMutation, FinalizeUploadedProjectAvatarMutationVariables>(FinalizeUploadedProjectAvatarDocument);
};
export const FinalizeUploadedProjectGroupAvatarDocument = gql`
    mutation FinalizeUploadedProjectGroupAvatar($input: FinalizeUploadedProjectGroupAvatarInput!) {
  FinalizeUploadedProjectGroupAvatar(input: $input) {
    __typename
    ... on FinalizeUploadedProjectGroupAvatarSuccess {
      project_group_id
    }
    ... on FinalizeUploadedProjectGroupAvatarErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedProjectGroupAvatarMutation() {
  return Urql.useMutation<FinalizeUploadedProjectGroupAvatarMutation, FinalizeUploadedProjectGroupAvatarMutationVariables>(FinalizeUploadedProjectGroupAvatarDocument);
};
export const FinalizeUploadedProjectBackgroundDocument = gql`
    mutation finalizeUploadedProjectBackground($input: FinalizeUploadedProjectBackgroundInput!) {
  finalizeUploadedProjectBackground(input: $input) {
    __typename
    ... on FinalizeUploadedProjectBackgroundSuccess {
      project_id
    }
    ... on FinalizeUploadedProjectBackgroundErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedProjectBackgroundMutation() {
  return Urql.useMutation<FinalizeUploadedProjectBackgroundMutation, FinalizeUploadedProjectBackgroundMutationVariables>(FinalizeUploadedProjectBackgroundDocument);
};
export const FinalizeUploadedProjectBannerDocument = gql`
    mutation finalizeUploadedProjectBanner($input: FinalizeUploadedProjectBannerInput!) {
  finalizeUploadedProjectBanner(input: $input) {
    __typename
    ... on FinalizeUploadedProjectBannerSuccess {
      project_id
    }
    ... on FinalizeUploadedProjectBannerErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedProjectBannerMutation() {
  return Urql.useMutation<FinalizeUploadedProjectBannerMutation, FinalizeUploadedProjectBannerMutationVariables>(FinalizeUploadedProjectBannerDocument);
};
export const FinalizeUploadedUserAvatarDocument = gql`
    mutation finalizeUploadedUserAvatar($input: FinalizeUploadedUserAvatarInput!) {
  finalizeUploadedUserAvatar(input: $input) {
    __typename
    ... on FinalizeUploadedUserAvatarSuccess {
      user_id
    }
    ... on FinalizeUploadedUserAvatarErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedUserAvatarMutation() {
  return Urql.useMutation<FinalizeUploadedUserAvatarMutation, FinalizeUploadedUserAvatarMutationVariables>(FinalizeUploadedUserAvatarDocument);
};
export const FinalizeUploadedChannelAssetDocument = gql`
    mutation finalizeUploadedChannelAsset($input: FinalizeUploadedChannelAssetInput!) {
  finalizeUploadedChannelAsset(input: $input) {
    __typename
    ... on FinalizeUploadedChannelAssetSuccess {
      channel_id
      public_url
    }
    ... on FinalizeUploadedChannelAssetErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedChannelAssetMutation() {
  return Urql.useMutation<FinalizeUploadedChannelAssetMutation, FinalizeUploadedChannelAssetMutationVariables>(FinalizeUploadedChannelAssetDocument);
};
export const FinalizeUploadedFileStructureDocument = gql`
    mutation finalizeUploadedFileStructure($input: FinalizeUploadedFileStructureInput!) {
  finalizeUploadedFileStructure(input: $input) {
    __typename
    ... on FinalizeUploadedFileStructureSuccess {
      parent_id
    }
    ... on FinalizeUploadedFileStructureErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedFileStructureMutation() {
  return Urql.useMutation<FinalizeUploadedFileStructureMutation, FinalizeUploadedFileStructureMutationVariables>(FinalizeUploadedFileStructureDocument);
};
export const FinalizeUploadedTaskSpreadsheetDocument = gql`
    mutation finalizeUploadedTaskSpreadsheet($input: FinalizeUploadedTaskSpreadsheetInput!) {
  finalizeUploadedTaskSpreadsheet(input: $input) {
    __typename
    ... on FinalizeUploadedTaskSpreadsheetSuccess {
      project_id
    }
    ... on FinalizeUploadedTaskSpreadsheetErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedTaskSpreadsheetMutation() {
  return Urql.useMutation<FinalizeUploadedTaskSpreadsheetMutation, FinalizeUploadedTaskSpreadsheetMutationVariables>(FinalizeUploadedTaskSpreadsheetDocument);
};
export const FinalizeUploadedProjectSpreadsheetDocument = gql`
    mutation finalizeUploadedProjectSpreadsheet($input: FinalizeUploadedProjectSpreadsheetInput!) {
  finalizeUploadedProjectSpreadsheet(input: $input) {
    __typename
    ... on FinalizeUploadedProjectSpreadsheetSuccess {
      org_id
    }
    ... on FinalizeUploadedProjectSpreadsheetErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useFinalizeUploadedProjectSpreadsheetMutation() {
  return Urql.useMutation<FinalizeUploadedProjectSpreadsheetMutation, FinalizeUploadedProjectSpreadsheetMutationVariables>(FinalizeUploadedProjectSpreadsheetDocument);
};
export const FinalizeFileVersionAnnotationsForUploadDocument = gql`
    mutation finalizeFileVersionAnnotationsForUpload {
  __typename
}
    `;

export function useFinalizeFileVersionAnnotationsForUploadMutation() {
  return Urql.useMutation<FinalizeFileVersionAnnotationsForUploadMutation, FinalizeFileVersionAnnotationsForUploadMutationVariables>(FinalizeFileVersionAnnotationsForUploadDocument);
};