import { getRandomColor } from "@clovis/server/src/app/config/colors/colors";
import { validationSchema } from "@clovis/server/src/app/domain/org-project-summary/update-org-project-summary/update-org-project-summary.validation";
import * as React from "react";
import { AllowedTo } from "react-abac";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import type { UseQueryState } from "urql";
import {
  formatDateToTimestamptzString,
  setHoursOnDateValue,
} from "~/config/dayjs";
import {
  nullable,
  nullableDate,
  timestamptzToDateValue,
  timestamptzToTimeValue,
  useForm,
} from "~/config/react-hook-form";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DateField } from "~/design-system/DateField";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { TextField } from "~/design-system/TextField";
import { Tones } from "~/design-system/tokens";
import type { BasicMultiSelectFieldInputOption } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { ClientFilterButton } from "~/screens/App/components/ClientFilterButton/ClientFilterButton";
import { OrgProjectCategoriesDrawer } from "~/screens/App/screens/Org/components/OrgProjectCategoriesDrawer/OrgProjectCategoriesDrawer";
import { OrgProjectGroupsDrawer } from "~/screens/App/screens/Org/components/OrgProjectGroupsDrawer/OrgProjectGroupsDrawer";
import {
  OrgAbacProvider,
  Permission,
} from "~/screens/App/screens/Org/Org.authorize";
import { createComponentHook } from "~/types";
import type {
  GetProjectClientsFormOptionsQuery,
  ProjectClientsFragment,
} from "./UpdateOrgProjectSummaryForm.graphql";
import {
  useCreateProjectCategoryMutation,
  useCreateProjectGroupMutation,
  useGetOrgProjectSummaryQuery,
  useGetProjectClientsFormOptionsQuery,
  useGetUpdateOrgProjectSummaryCategoriesOptionsQuery,
  useGetUpdateOrgProjectSummaryGroupsOptionsQuery,
  useUpdateOrgProjectSummaryMutation,
} from "./UpdateOrgProjectSummaryForm.graphql";

const getClientOptions = (
  responseClient: UseQueryState<GetProjectClientsFormOptionsQuery, object>
) => {
  const userProjectsClients =
    responseClient?.data?.projects?.map((project: ProjectClientsFragment) => {
      return project.org_project_summary?.[0]?.client_fullname;
    }) ?? [];

  const orgProjectsClients =
    responseClient?.data?.projects_map_overview?.map(
      (project: ProjectClientsFragment) => {
        return project.org_project_summary?.[0]?.client_fullname;
      }
    ) ?? [];

  const clientsSet = new Set();

  [...userProjectsClients, ...orgProjectsClients].forEach((client) => {
    if (client) {
      clientsSet.add(client);
    }
  });

  const clientList = [...clientsSet];

  const clientOptions: BasicMultiSelectFieldInputOption<string>[] =
    clientList.map((client) => {
      return {
        label: client as string,
        value: client as string,
      };
    });

  return clientOptions;
};

const useUpdateOrgProjectSummaryForm = createComponentHook(
  (props: UpdateOrgProjectSummaryFormProps) => {
    const { t } = useTranslation();

    const form = useForm(validationSchema, {
      defaultValues: {
        id: props.orgProjectSummaryId,
      },
    });

    const [startHourDisabled, setStartHourDisabled] = React.useState<boolean>(
      !form.getValues("start_date")
    );
    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["project_categories"] }),
      []
    );
    const [{ data: orgProjectSummaryCategoriesOptions }] =
      useGetUpdateOrgProjectSummaryCategoriesOptionsQuery({
        context: queryContext,
        variables: { orgId: props.orgId },
      });

    const queryGroupContext = React.useMemo(
      () => ({ additionalTypenames: ["project_groups"] }),
      []
    );

    const [{ data: orgProjectSummaryGroupsOptions }] =
      useGetUpdateOrgProjectSummaryGroupsOptionsQuery({
        context: queryGroupContext,
        variables: { orgId: props.orgId },
      });

    const [{ data }] = useGetOrgProjectSummaryQuery({
      variables: { orgProjectSummaryId: props.orgProjectSummaryId },
    });

    const [responseClient] = useGetProjectClientsFormOptionsQuery();
    const clientOptions = getClientOptions(responseClient);

    const currentData = data?.org_project_summary_by_pk;
    const [projectId, setProjectId] = React.useState<string | null | undefined>(
      ""
    );

    React.useEffect(() => {
      if (
        data?.org_project_summary_by_pk?.project_id !== undefined &&
        data?.org_project_summary_by_pk?.project_id !== null &&
        typeof data?.org_project_summary_by_pk?.project_id === "string"
      ) {
        setProjectId(data.org_project_summary_by_pk.project_id);
      }
    }, [data]);

    const [categoriesOptions, setCategoriesOptions] = React.useState<
      {
        label: string;
        value: string;
        color: string;
        type: BasicMultiSelectFieldInputOption["type"];
      }[]
    >([]);

    const [projectGroupsOptions, setProjectGroupsOptions] = React.useState<
      {
        label: string;
        value: string;
        color: string;
        type: BasicMultiSelectFieldInputOption["type"];
      }[]
    >([]);

    // TODO: add fetching error handling
    React.useEffect(() => {
      // Wait until we have retrieved the options before re-dispatching the form values
      if (orgProjectSummaryCategoriesOptions) {
        // Extract values to be used in MultiSelectInput from all the options
        setCategoriesOptions(
          orgProjectSummaryCategoriesOptions?.project_categories.map((pc) => ({
            color: pc.color,
            label: pc.name,
            type: BasicMultiSelectFieldInputOptionType.PROJECT_CATEGORY,
            value: pc.id,
          })) ?? []
        );
      }

      if (orgProjectSummaryGroupsOptions) {
        setProjectGroupsOptions(
          orgProjectSummaryGroupsOptions?.project_groups.map((pc) => ({
            avatar: pc.avatar,
            color: pc.color,
            label: pc.name,
            type: pc.avatar
              ? BasicMultiSelectFieldInputOptionType.ORG
              : BasicMultiSelectFieldInputOptionType.AUTOCOMPLETE,
            value: pc.id,
          })) ?? []
        );
      }

      // Based on the file actual retrieved associations, use the reset form to dispatch "async default values"
      // as recommended by react-hook-form (see: https://github.com/react-hook-form/react-hook-form/issues/2492#issuecomment-706468617)
      if (
        currentData &&
        orgProjectSummaryCategoriesOptions &&
        orgProjectSummaryGroupsOptions
      ) {
        const {
          end_date,
          project_categories,
          project_groups,
          start_date,
          ...rest
        } = currentData;
        form.reset({
          ...rest,
          categories: project_categories.map((c) => c.project_category.id),
          groups: project_groups.map((c) => c.project_group.id),
          id: props.orgProjectSummaryId,
        });
        /*
         ** We have to set our dates values with setValue, to only retrieve the "date" part from it.
         ** Then, we need to lie about their types to make it play nice with two sources of truth:
         ** 1. input value type expect a string like "2021-01-01" for input type "date"
         ** 2. our validationSchema use the date() type and therefore our typescript expect a type Date | undefined
         */
        form.setValue("start_date", timestamptzToDateValue(start_date));
        form.setValue("end_date", timestamptzToDateValue(end_date));
        form.setValue("start_hour", timestamptzToTimeValue(start_date));
        form.setValue("end_hour", timestamptzToTimeValue(end_date));
        setStartHourDisabled(!form.getValues("start_date"));
      }
      // We don't add the "form" as dependency since we want this effect to
      // be ran once after form data are retrieved, and not evrytime the values
      // of the form change after that
    }, [
      orgProjectSummaryCategoriesOptions,
      currentData,
      props.orgProjectSummaryId,
      orgProjectSummaryGroupsOptions,
    ]);

    const [, updateOrgProjectSummary] = useUpdateOrgProjectSummaryMutation();

    const handleSubmit = form.handleSubmit(async (input) => {
      const { end_hour, start_hour, ...restOfInput } = input;
      const { data } = await updateOrgProjectSummary(
        {
          input: {
            ...restOfInput,
            end_date: formatDateToTimestamptzString(
              setHoursOnDateValue(input.end_date, end_hour)
            ),
            start_date: formatDateToTimestamptzString(
              setHoursOnDateValue(input.start_date, start_hour)
            ),
          },
        },
        // Used to reload all queries depending on files
        // TODO: find a less brutal way to granulary reload only what necessary
        { additionalTypenames: ["org_project_summary"] }
      );
      if (
        data?.updateOrgProjectSummary?.__typename ===
        "UpdateOrgProjectSummarySuccess"
      ) {
        toast.success(
          t(
            "screens.UpdateOrgProjectSummaryForm.updateOrgProjectSummarySuccessToast",
            "Your informations has been saved"
          )
        );
        props.onSuccess?.(props.orgProjectSummaryId);
      } else {
        toast.error(
          t(
            "screens.UpdateOrgProjectSummaryForm.updateOrgProjectSummaryErrorToast",
            "An error occured while trying to save your infos"
          )
        );
      }
    });
    const handleClearStartDate = () => {
      form.setValue("start_date", null);
      form.setValue("start_hour", null);
      form.setValue("end_hour", null);
      setStartHourDisabled(true);
    };
    const handleClearEndDate = () => {
      form.setValue("end_date", null);
    };

    const handleClearStartHour = () => {
      form.setValue("start_hour", null);
    };

    const handleClearEndHour = () => {
      form.setValue("end_hour", null);
    };

    const [, createProjectCategory] = useCreateProjectCategoryMutation();

    const handleCreateOption = async (label: string) => {
      const randomColor = getRandomColor();

      const { data } = await createProjectCategory({
        input: {
          // Select a random color from our SelectColorField palette
          color: randomColor,
          name: label,
          orgId: props.orgId,
        },
      });
      if (
        data?.createProjectCategory?.__typename ===
        "CreateProjectCategorySuccess"
      ) {
        const newOption = {
          color: randomColor,
          label,
          type: BasicMultiSelectFieldInputOptionType.PROJECT_CATEGORY,
          value: data?.createProjectCategory.project_category_id,
        };

        const currentValues = form.getValues("categories");
        form.setValue("categories", [
          ...(currentValues ?? []),
          newOption.value,
        ]);

        setCategoriesOptions([...categoriesOptions, newOption]);
      } else {
        toast.error(
          t(
            "screens.UpdateOrgProjectSummaryForm.createProjectCategoryErrorsToast",
            "Something went wrong while creating the category."
          )
        );
      }
    };

    const [, createProjectGroup] = useCreateProjectGroupMutation();
    const handleCreateGroupsOption = async (label: string) => {
      const randomColor = getRandomColor();

      const { data } = await createProjectGroup({
        input: {
          color: randomColor,
          name: label,
          orgId: props.orgId,
        },
      });
      if (
        data?.createProjectGroup?.__typename === "CreateProjectGroupSuccess"
      ) {
        const newOption = {
          avatar: undefined,
          color: randomColor,
          label,
          type: BasicMultiSelectFieldInputOptionType.AUTOCOMPLETE,
          value: data?.createProjectGroup.project_group_id,
        };

        const currentValues = form.getValues("groups");
        form.setValue("groups", [...(currentValues ?? []), newOption.value]);

        setProjectGroupsOptions([...projectGroupsOptions, newOption]);
      } else {
        toast.error(
          t(
            "screens.UpdateOrgProjectSummaryForm.createProjectGroupErrorsToast",
            "Something went wrong while creating the Group."
          )
        );
      }
    };

    const [clientName, setClientName] = React.useState<
      string | null | undefined
    >();

    const onChangeStartDate = (value: Date | undefined) => {
      form.setValue("start_date", value);
      setStartHourDisabled(false);
    };

    React.useEffect(() => {
      setClientName(form.getValues("client_fullname"));
    }, [form.formState]);

    const hours = {
      hours_done: form.watch("hours_done"),
      hours_expected: form.watch("hours_expected"),
    };

    return {
      actions: {
        handleClearEndDate,
        handleClearEndHour,
        handleClearStartDate,
        handleClearStartHour,
        handleCreateGroupsOption,
        handleCreateOption,
        handleSubmit,
        onChangeStartDate,
        registerInput: form.register,
        setInputValue: form.setValue,
      },
      state: {
        categoriesOptions: categoriesOptions,
        clientName,
        clientOptions,
        control: form.control,
        errors: form.formState.errors,
        hours,
        projectGroupsOptions,
        projectId,
        startHourDisabled,
        validationSchema,
      },
      t,
    };
  }
);

// To ensure compatibility setOpenCategoryDrawer and setOpenProjectSummaryDrawer must be optional
// because they are used in a higher level component UpdateOrgProjectSummaryDrawerContent
// which is call in other views e.g ProjectsGantt.tsx line 364
type UpdateOrgProjectSummaryFormProps = {
  orgProjectSummaryId: string;
  orgId: string;
  onSuccess?: (orgProjectSummaryId: string) => unknown;
  setOpenCategoryDrawer?: (open: boolean) => void;
  setOpenProjectSummaryDrawer?: (open: boolean) => void;
};
function UpdateOrgProjectSummaryForm(props: UpdateOrgProjectSummaryFormProps) {
  const { actions, state, t } = useUpdateOrgProjectSummaryForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="gutter">
        <BasicMultiSelectFieldInput
          label={t(
            "screens.UpdateOrgProjectSummaryForm.categoriesMultiSelectInputLabel",
            "Project Categories"
          )}
          cornerHint={
            <OrgAbacProvider>
              <AllowedTo perform={Permission.CreateProjectGroup}>
                {props.setOpenCategoryDrawer ? (
                  <Button
                    variant="text"
                    css={{
                      display: "inline-flex",
                      marginLeft: "10px",
                      maxWidth: "100%",
                      minHeight: "fit-content",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      props.setOpenCategoryDrawer?.(true);
                      props.setOpenProjectSummaryDrawer?.(false);
                    }}
                    dataIntercomTarget="project-profile-org-project-summary-update-edit-project-categories"
                  >
                    <Text size="small" tone="link">
                      {t(
                        "screens.UpdateOrgProjectSummaryForm.editProjectCategories",
                        "edit Project's Categories"
                      )}
                    </Text>
                  </Button>
                ) : (
                  <OrgProjectCategoriesDrawer orgId={props.orgId}>
                    <DrawerTrigger>
                      <Text size="small" tone="link">
                        {t(
                          "screens.UpdateOrgProjectSummaryForm.editProjectCategories",
                          "edit Project's Categories"
                        )}
                      </Text>
                    </DrawerTrigger>
                  </OrgProjectCategoriesDrawer>
                )}
              </AllowedTo>
            </OrgAbacProvider>
          }
          control={state.control}
          options={state.categoriesOptions}
          onCreateOption={actions.handleCreateOption}
          {...actions.registerInput("categories")}
          dataIntercomTarget="org-project-summary-update-categories"
        />
        <BasicMultiSelectFieldInput
          label={t(
            "screens.UpdateOrgProjectSummaryForm.projectGroupsMultiSelectInputLabel",
            "Project Groups"
          )}
          cornerHint={
            <OrgAbacProvider>
              <AllowedTo perform={Permission.CreateProjectGroup}>
                <OrgProjectGroupsDrawer orgId={props.orgId}>
                  <DrawerTrigger>
                    <Text size="small" tone="link">
                      {t(
                        "screens.UpdateOrgProjectSummaryForm.editProjectGroups",
                        "Edit Project groups"
                      )}
                    </Text>
                  </DrawerTrigger>
                </OrgProjectGroupsDrawer>
              </AllowedTo>
            </OrgAbacProvider>
          }
          control={state.control}
          options={state.projectGroupsOptions}
          onCreateOption={actions.handleCreateGroupsOption}
          {...actions.registerInput("groups")}
          dataIntercomTarget="org-project-summary-update-groups"
        />
        <TextField
          {...actions.registerInput("reference", { setValueAs: nullable })}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.referenceLabel",
            "Reference"
          )}
          tone={Tones.neutral}
          error={state.errors.reference?.message}
          dataIntercomTarget="org-project-summary-update-reference"
        />
        <DateField
          {...actions.registerInput("start_date", {
            setValueAs: nullableDate,
          })}
          onChange={({ target }) => {
            actions.onChangeStartDate(timestamptzToDateValue(target.value));
          }}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.startDatePlaceholder",
            "Start Date"
          )}
          onClear={actions.handleClearStartDate}
          dataIntercomTarget="org-project-summary-update-start_date"
        />
        <DateField
          {...actions.registerInput("start_hour")}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.startHourPlaceholder",
            "Start Hour"
          )}
          type="time"
          disabled={state.startHourDisabled}
          onClear={actions.handleClearStartHour}
          dataIntercomTarget="org-project-summary-update-start_hour"
        />
        <DateField
          {...actions.registerInput("end_date", {
            setValueAs: nullableDate,
          })}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.endDatePlaceholder",
            "End Date"
          )}
          onClear={actions.handleClearEndDate}
          dataIntercomTarget="org-project-summary-update-end_date"
        />
        <DateField
          {...actions.registerInput("end_hour")}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.endHourPlaceholder",
            "End Hour"
          )}
          type="time"
          disabled={state.startHourDisabled}
          onClear={actions.handleClearEndHour}
          dataIntercomTarget="org-project-summary-update-end_hour"
        />
        <Columns space="small" collapseBelow="desktop">
          <Column>
            <TextField
              type="number"
              onChange={(e) => {
                e.stopPropagation();
                actions.setInputValue(
                  "hours_expected",
                  parseInt(e.target.value)
                );
              }}
              label={t(
                "screens.UpdateOrgProjectSummaryForm.HourExpected",
                "Hours Expected"
              )}
              name="hours_expected"
              dataIntercomTarget="org-project-summary-update-hours_expected"
              value={state.hours.hours_expected ?? 0}
            />
          </Column>
          <Column>
            <TextField
              type="number"
              onChange={(e) => {
                e.stopPropagation();
                actions.setInputValue("hours_done", parseInt(e.target.value));
              }}
              label={t(
                "screens.UpdateOrgProjectSummaryForm.HourDone",
                "Hours Done"
              )}
              name="hours_expected"
              dataIntercomTarget="org-project-summary-update-hours_expected"
              value={state.hours.hours_done ?? 0}
            />
          </Column>
        </Columns>
        <ClientFilterButton
          options={state.clientOptions}
          name="client_fullname"
          control={state.control}
          registerInput={actions.registerInput}
          onSelect={(value) => {
            actions.setInputValue("client_fullname", value);
          }}
          value={state.clientName}
          onRemove={() => {
            actions.setInputValue("client_fullname", null);
          }}
          error={state.errors.client_fullname?.message}
        />
        <TextField
          {...actions.registerInput("client_email", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.clientEmailLabel",
            "Client Email"
          )}
          error={state.errors.client_email?.message}
          dataIntercomTarget="org-project-summary-update-client_email"
        />
        <TextField
          {...actions.registerInput("client_phone_number", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.clientPhoneLabel",
            "Client Phone"
          )}
          error={state.errors.client_phone_number?.message}
          dataIntercomTarget="org-project-summary-update-client_phone_number"
        />
        <TextField
          {...actions.registerInput("price_estimate", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.UpdateOrgProjectSummaryForm.priceEstimateLabel",
            "Price Estimate"
          )}
          error={state.errors.price_estimate?.message}
          dataIntercomTarget="org-project-summary-update-price_estimate"
        />
        <Box to={`/project/${state.projectId}/info/profile/edit`} as={Link}>
          <Button
            size="large"
            variant="ghost"
            //tone="brandAccent"
            dataIntercomTarget="project-profile-update"
            onClick={() => null}
            width="full"
          >
            {t(
              "screens.UpdateOrgProjectSummaryForm.editProjectDetails",
              "Edit project details (avatar, address, etc...)"
            )}
          </Button>
        </Box>
        <Button
          size="large"
          type="submit"
          tone="brandAccent"
          width="full"
          dataIntercomTarget="project-profile-update"
        >
          {t("screens.UpdateOrgProjectSummaryForm.updateButton", "Update")}
        </Button>
      </Stack>
    </Form>
  );
}

export type { UpdateOrgProjectSummaryFormProps };
export { getClientOptions, UpdateOrgProjectSummaryForm };
