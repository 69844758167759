import { validationSchema } from "@clovis/server/src/app/domain/team/update-users-in-team/update-users-in-team.validation";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { createComponentHook } from "~/types";
import {
  useGetTeamMembersOptionsQuery,
  useGetTeamMembersQuery,
  useUpdateUsersInTeamMutation,
} from "./UpdateTeamMembersForm.graphql";

const useUpdateTeamMembersForm = createComponentHook(
  (props: UpdateTeamMembersFormProps) => {
    const { t } = useTranslation();

    const form = useForm(validationSchema, {
      defaultValues: {
        teamId: props.teamId,
        userIds: [],
      },
    });

    const [{ data: teamMembers }] = useGetTeamMembersQuery({
      variables: { team_id: props.teamId },
    });

    const [{ data: teamMembersOptions }] = useGetTeamMembersOptionsQuery({
      pause: teamMembers?.teams_by_pk?.project.id === undefined,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        project_id: teamMembers?.teams_by_pk?.project.id!,
      },
    });

    React.useEffect(() => {
      if (teamMembersOptions) {
        if (teamMembers) {
          form.reset({
            teamId: props.teamId,
            userIds: teamMembers.teams_by_pk?.users.map((u) => u.user.id) ?? [],
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamMembers, teamMembersOptions, props.teamId]);

    const usersAssignationsOptions =
      teamMembersOptions?.projects_by_pk?.members.map((u) => ({
        avatar: u.user.avatar,
        label: u.user.full_name!,
        type: BasicMultiSelectFieldInputOptionType.USER,
        value: u.user.id,
      })) ?? [];

    const [, updateUsersInTeam] = useUpdateUsersInTeamMutation();

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data } = await updateUsersInTeam(
        {
          input: input,
        },
        { additionalTypenames: ["teams"] }
      );
      if (data?.updateUsersInTeam?.__typename === "UpdateUsersInTeamSuccess") {
        toast.success(
          t(
            "screens.Projects.components.UpdateTeamMembersDialog.UpdateTeamMembersForm.updateTeamMembersSuccessToast",
            "Team members have been updated"
          )
        );
        props.onSuccess?.();
      } else {
        toast.error(
          t(
            "screens.Projects.components.UpdateTeamMembersDialog.UpdateTeamMembersForm.updateTeamMembersErrorToast",
            "An error occured while trying to updating the members of the team"
          )
        );
      }
    });

    return {
      actions: {
        handleSubmit,
        registerInput: form.register,
      },
      state: {
        control: form.control,
        errors: form.formState.errors,
        usersSelectOptions: usersAssignationsOptions,
      },
      t,
    };
  }
);

type UpdateTeamMembersFormProps = {
  teamId: string;
  onSuccess?: () => void;
};
function UpdateTeamMembersForm(props: UpdateTeamMembersFormProps) {
  const { actions, state, t } = useUpdateTeamMembersForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Box css={{ paddingBottom: "$8" }}>
        <BasicMultiSelectFieldInput
          required
          label={t(
            "screens.Projects.components.UpdateTeamMembersDialog.UpdateTeamMembersForm.usersMultiSelectInputLabel",
            "Users"
          )}
          control={state.control}
          options={state.usersSelectOptions}
          {...actions.registerInput("userIds")}
          dataIntercomTarget="teams-members-update-users"
        />
      </Box>
      <Button
        size="large"
        type="submit"
        tone="brandAccent"
        width="full"
        dataIntercomTarget="project-update-team-members-save"
      >
        {t(
          "screens.Projects.components.UpdateTeamMembersDialog.UpdateTeamMembersForm.saveButton",
          "Save"
        )}
      </Button>
    </Form>
  );
}

export type { UpdateTeamMembersFormProps };
export { UpdateTeamMembersForm };
