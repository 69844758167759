import { validationSchema } from "@clovis/server/src/app/domain/team/create-team/create-team.validation";
import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useAbac } from "react-abac";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { Permission } from "~/screens/App/screens/Project/Project.authorize";
import { createComponentHook } from "~/types";
import { SelectColorField } from "../../../../../../../../components/SelectColorField/SelectColorField";
import { useCreateTeamMutation } from "./CreateTeamForm.graphql";

const useCreateTeamForm = createComponentHook((props: CreateTeamFormProps) => {
  const { t } = useTranslation();
  const [addMode, setAddMode] = React.useState(false);
  const [, createTeam] = useCreateTeamMutation();
  const { userHasPermissions } = useAbac();
  const form = useForm(validationSchema, {
    defaultValues: {
      projectId: props.projectId,
    },
  });

  const enableForm = () => {
    setAddMode(true);
  };

  const disableForm = () => {
    form.reset();
    setAddMode(false);
  };

  const handleSubmit = form.handleSubmit(async (input) => {
    const { data } = await createTeam(
      {
        input,
      },
      { additionalTypenames: ["teams"] }
    );
    if (data?.createTeam?.__typename === "CreateTeamSuccess") {
      toast.success(
        t(
          "screens.ProjectTeamsDrawer.CreateTeamForm.createTeamSuccessToast",
          "Your team has been added"
        )
      );
      disableForm();
    } else {
      toast.error(
        t(
          "screens.ProjectTeamsDrawer.CreateTeamForm.createTeamErrorsToast",
          "Something went wrong while creating the team."
        )
      );
    }
  });

  return {
    actions: {
      disableForm,
      enableForm,
      handleSubmit,
      registerInput: form.register,
      userHasPermissions,
    },
    state: {
      addMode,
      control: form.control,
      errors: form.formState.errors,
    },
    t,
  };
});

type CreateTeamFormProps = {
  projectId: string;
};

function CreateTeamForm(props: CreateTeamFormProps) {
  const { actions, state, t } = useCreateTeamForm(props);

  if (state.addMode) {
    return (
      <Form onSubmit={actions.handleSubmit}>
        <Stack space="small">
          <TextField
            {...actions.registerInput("name")}
            label={t(
              "screens.ProjectTeamsDrawer.CreateTeamForm.nameLabel",
              "Name"
            )}
            error={state.errors.name?.message}
            required
            dataIntercomTarget="teams-create-name"
          />
          <SelectColorField
            {...actions.registerInput("color")}
            label={t(
              "screens.ProjectTeamsDrawer.CreateTeamForm.colorLabel",
              "Color"
            )}
            control={state.control}
            error={state.errors.color?.message}
            required
            dataIntercomTarget="teams-create-color"
          />
          <Columns space="small">
            <Column>
              <Button
                size="large"
                variant="soft"
                width="full"
                onClick={actions.disableForm}
                type="button"
                dataIntercomTarget="project-teams-create-cancel"
              >
                {t(
                  "screens.ProjectTeamsDrawer.CreateTeamForm.cancelButton",
                  "Cancel"
                )}
              </Button>
            </Column>
            <Column>
              <Button
                size="large"
                tone="brandAccent"
                width="full"
                disabled={
                  !actions.userHasPermissions(Permission.UpdateProjectTeams)
                }
                dataIntercomTarget="project-teams-create-submit"
              >
                {t(
                  "screens.ProjectTeamsDrawer.CreateTeamForm.submitButton",
                  "Create team"
                )}
              </Button>
            </Column>
          </Columns>
        </Stack>
      </Form>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Button
        type="button"
        tone="brandAccent"
        leadingIcon={<PlusSmIcon />}
        onClick={actions.enableForm}
        disabled={!actions.userHasPermissions(Permission.UpdateProjectTeams)}
        dataIntercomTarget="project-teams-create"
      >
        {t(
          "screens.ProjectTeamsDrawer.CreateTeamForm.enableButton",
          "Add a team to the project"
        )}
      </Button>
    </Box>
  );
}

export { CreateTeamForm };
