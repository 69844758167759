import * as React from "react";
import create from "zustand";
import createContext from "zustand/context";
import type { ToolValue } from "./UserStyles.constants";
import { COLORS, Tool } from "./UserStyles.constants";

type UserStyleState = {
  colorGlobal: string;
  setColorGlobal: (color: string) => void;
  thicknessGlobal: number;
  setThicknessGlobal: (thickness: number) => void;
  fontSizeGlobal: number;
  setFontSizeGlobal: (fontSize: number) => void;
  toolGlobal: ToolValue;
  setToolGlobal: (tool: ToolValue) => void;
};

const { Provider, useStore: useUserAnnotationToolsPreferences } =
  createContext<UserStyleState>();

type UserAnnotationToolsPreferencesProviderProps = {
  children: React.ReactNode;
};

function UserAnnotationToolsPreferencesProvider(
  props: UserAnnotationToolsPreferencesProviderProps
) {
  return (
    <Provider
      createStore={() =>
        create<UserStyleState>((set) => ({
          colorGlobal: COLORS[0],
          fontSizeGlobal: 16,
          setColorGlobal: (colorGlobal) => set({ colorGlobal }),
          setFontSizeGlobal: (fontSizeGlobal) => set({ fontSizeGlobal }),
          setThicknessGlobal: (thicknessGlobal) => set({ thicknessGlobal }),
          setToolGlobal: (toolGlobal) => set({ toolGlobal }),
          thicknessGlobal: 2,
          toolGlobal: Tool.FreeHand,
        }))
      }
    >
      {props.children}
    </Provider>
  );
}

export {
  UserAnnotationToolsPreferencesProvider,
  useUserAnnotationToolsPreferences,
};
