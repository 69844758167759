import type * as Types from '../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserFragment = { id: string, full_name?: string | null | undefined, is_connected: boolean, avatar?: string | null | undefined, active_org?: { org: { name: string, id: string, avatar?: string | null | undefined } } | null | undefined, contact?: { email?: string | null | undefined, phone?: string | null | undefined } | null | undefined, connections: Array<{ created_at: string }> };

export type UserProfileContainer_DataQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type UserProfileContainer_DataQuery = { users_by_pk?: { id: string, full_name?: string | null | undefined, is_connected: boolean, avatar?: string | null | undefined, active_org?: { org: { name: string, id: string, avatar?: string | null | undefined } } | null | undefined, contact?: { email?: string | null | undefined, phone?: string | null | undefined } | null | undefined, connections: Array<{ created_at: string }> } | null | undefined };

export type UserProjectInfo_DataQueryVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
  userId: Types.Scalars['uuid'];
}>;


export type UserProjectInfo_DataQuery = { projects_by_pk?: { id: string, name: string, members: Array<{ created_at: string, role: { name: string }, user: { first_name: string, last_name: string, is_active: boolean, id: string, full_name?: string | null | undefined, is_connected: boolean, avatar?: string | null | undefined, teams: Array<{ team_id: string, team: { name: string, color: string } }>, active_org?: { org: { name: string, id: string, avatar?: string | null | undefined } } | null | undefined, contact?: { email?: string | null | undefined, phone?: string | null | undefined } | null | undefined, connections: Array<{ created_at: string }> }, inviter?: { id: string, avatar?: string | null | undefined, full_name?: string | null | undefined } | null | undefined }> } | null | undefined };

export const UserFragmentDoc = gql`
    fragment User on users {
  id
  full_name
  is_connected
  avatar
  active_org {
    org {
      name
      id
      avatar
    }
  }
  contact {
    email
    phone
  }
  connections(order_by: {created_at: desc}, limit: 1) {
    created_at
  }
}
    `;
export const UserProfileContainer_DataDocument = gql`
    query UserProfileContainer_data($userId: uuid!) {
  users_by_pk(id: $userId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUserProfileContainer_DataQuery(options: Omit<Urql.UseQueryArgs<UserProfileContainer_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserProfileContainer_DataQuery>({ query: UserProfileContainer_DataDocument, ...options });
};
export const UserProjectInfo_DataDocument = gql`
    query UserProjectInfo_data($projectId: uuid!, $userId: uuid!) {
  projects_by_pk(id: $projectId) {
    id
    name
    members(where: {user: {id: {_eq: $userId}}}) {
      role {
        name
      }
      user {
        ...User
        first_name
        last_name
        teams(where: {team: {project_id: {_eq: $projectId}}}) {
          team_id
          team {
            name
            color
          }
        }
        is_active
      }
      inviter {
        id
        avatar
        full_name
      }
      created_at
    }
  }
}
    ${UserFragmentDoc}`;

export function useUserProjectInfo_DataQuery(options: Omit<Urql.UseQueryArgs<UserProjectInfo_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserProjectInfo_DataQuery>({ query: UserProjectInfo_DataDocument, ...options });
};