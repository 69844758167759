import { validationSchema } from "@clovis/server/src/app/domain/org-project-summary/create-org-project-summary/create-org-project-summary.validation";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { formatDateToTimestamptzString } from "~/config/dayjs";
import { nullable, nullableDate, useForm } from "~/config/react-hook-form";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DateField } from "~/design-system/DateField";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { TextField } from "~/design-system/TextField";
import { Tones } from "~/design-system/tokens";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { OrgProjectCategoriesDrawer } from "~/screens/App/screens/Org/components/OrgProjectCategoriesDrawer/OrgProjectCategoriesDrawer";
import { createComponentHook } from "~/types";
import {
  useCreateOrgProjectSummaryMutation,
  useGetCreateOrgProjectSummaryCategoriesOptionsQuery,
} from "./CreateOrgProjectSummaryForm.graphql";

const useCreateOrgProjectSummaryForm = createComponentHook(
  (props: CreateOrgProjectSummaryFormProps) => {
    const { t } = useTranslation();

    const form = useForm(validationSchema, {
      defaultValues: {
        categories: [],
        orgId: props.orgId,
        projectId: props.projectId,
      },
    });

    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["project_categories"] }),
      []
    );
    const [{ data: orgProjectSummaryCategoriesOptions }] =
      useGetCreateOrgProjectSummaryCategoriesOptionsQuery({
        context: queryContext,
        variables: { orgId: props.orgId },
      });

    // Extract values to be used in MultiSelectInput from all the options
    const categoriesOptions =
      orgProjectSummaryCategoriesOptions?.project_categories.map((pc) => ({
        color: pc.color,
        label: pc.name,
        type: BasicMultiSelectFieldInputOptionType.PROJECT_CATEGORY,
        value: pc.id,
      })) ?? [];

    const [, createOrgProjectSummary] = useCreateOrgProjectSummaryMutation();

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data } = await createOrgProjectSummary(
        {
          input: {
            ...input,
            end_date: formatDateToTimestamptzString(input.end_date),
            start_date: formatDateToTimestamptzString(input.start_date),
          },
        },
        // Used to reload all queries depending on files
        // TODO: find a less brutal way to granulary reload only what necessary
        { additionalTypenames: ["org_project_summary"] }
      );
      if (
        data?.createOrgProjectSummary?.__typename ===
        "CreateOrgProjectSummarySuccess"
      ) {
        toast.success(
          t(
            "screens.CreateOrgProjectSummaryForm.createOrgProjectSummarySuccessToast",
            "Your org project infos has been created !"
          )
        );
        props.onSuccess?.(data.createOrgProjectSummary.org_project_summary_id);
      } else {
        toast.error(
          t(
            "screens.CreateOrgProjectSummaryForm.createOrgProjectSummaryErrorToast",
            "An error occured while trying to save your infos"
          )
        );
      }
    });

    const handleClearStartDate = () => {
      form.setValue("start_date", null);
    };
    const handleClearEndDate = () => {
      form.setValue("end_date", null);
    };

    return {
      actions: {
        handleClearEndDate,
        handleClearStartDate,
        handleSubmit,
        registerInput: form.register,
      },
      state: {
        categoriesOptions: categoriesOptions,
        control: form.control,
        errors: form.formState.errors,
      },
      t,
    };
  }
);

type CreateOrgProjectSummaryFormProps = {
  projectId: string;
  orgId: string;
  onSuccess?: (orgProjectSummaryId: string) => unknown;
};
function CreateOrgProjectSummaryForm(props: CreateOrgProjectSummaryFormProps) {
  const { actions, state, t } = useCreateOrgProjectSummaryForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="gutter">
        <BasicMultiSelectFieldInput
          label={t(
            "screens.CreateOrgProjectSummaryForm.categoriesMultiSelectInputLabel",
            "Project Categories"
          )}
          cornerHint={
            <OrgProjectCategoriesDrawer orgId={props.orgId}>
              <DrawerTrigger>
                <Text size="small" tone="link">
                  {t(
                    "screens.CreateOrgProjectSummaryForm.editProjectCategories",
                    "edit Project's Categories"
                  )}
                </Text>
              </DrawerTrigger>
            </OrgProjectCategoriesDrawer>
          }
          control={state.control}
          options={state.categoriesOptions}
          {...actions.registerInput("categories")}
          dataIntercomTarget="org-project-summary-create-project_categories"
        />
        <TextField
          {...actions.registerInput("reference", { setValueAs: nullable })}
          label={t(
            "screens.CreateOrgProjectSummaryForm.referenceLabel",
            "Reference"
          )}
          tone={Tones.neutral}
          error={state.errors.reference?.message}
          dataIntercomTarget="org-project-summary-create-reference"
        />
        <Columns space="small">
          <Column>
            <DateField
              {...actions.registerInput("start_date", {
                setValueAs: nullableDate,
              })}
              label={t(
                "screens.CreateOrgProjectSummaryForm.startDatePlaceholder",
                "Start Date"
              )}
              onClear={actions.handleClearStartDate}
              dataIntercomTarget="org-project-summary-create-start_date"
            />
          </Column>
          <Column>
            <DateField
              {...actions.registerInput("end_date", {
                setValueAs: nullableDate,
              })}
              label={t(
                "screens.CreateOrgProjectSummaryForm.endDatePlaceholder",
                "End Date"
              )}
              onClear={actions.handleClearEndDate}
              dataIntercomTarget="org-project-summary-create-end_date"
            />
          </Column>
        </Columns>
        <TextField
          {...actions.registerInput("client_fullname", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.CreateOrgProjectSummaryForm.clientNameLabel",
            "Client Name"
          )}
          error={state.errors.client_email?.message}
          dataIntercomTarget="org-project-summary-create-client_fullname"
        />
        <TextField
          {...actions.registerInput("client_email", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.CreateOrgProjectSummaryForm.clientEmailLabel",
            "Client Email"
          )}
          error={state.errors.client_email?.message}
          dataIntercomTarget="org-project-summary-create-client_email"
        />
        <TextField
          {...actions.registerInput("client_phone_number", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.CreateOrgProjectSummaryForm.clientPhoneLabel",
            "Client Phone"
          )}
          error={state.errors.client_fullname?.message}
          dataIntercomTarget="org-project-summary-create-client_phone_number"
        />
        <TextField
          {...actions.registerInput("price_estimate", {
            setValueAs: nullable,
          })}
          label={t(
            "screens.CreateOrgProjectSummaryForm.priceEstimateLabel",
            "Price Estimate"
          )}
          error={state.errors.price_estimate?.message}
          dataIntercomTarget="org-project-summary-create-price_estimate"
        />
        <Button
          size="large"
          type="submit"
          tone="brandAccent"
          width="full"
          dataIntercomTarget="project-profile-org-project-summary-create"
        >
          {t("screens.CreateOrgProjectSummaryForm.createButton", "Create")}
        </Button>
      </Stack>
    </Form>
  );
}

export type { CreateOrgProjectSummaryFormProps };
export { CreateOrgProjectSummaryForm };
